import {useStore} from "vuex";

const store = useStore();

async function backendRequest(url, data = null) {
    return await fetch('/config.json').then(res => res.json()).then(async function (config) {
        let formData = new FormData();
        if (!isEmpty(store?.state?.usertoken)) {
            formData.append('token', store.state.usertoken);
        }
        if (data !== null) {
            Object.keys(data).forEach(function(key) {
                formData.append(key, data[key]);
            });
        }
        return await fetch(`${config.api}` + url, {
            method: 'POST',
            body: formData,
        }).then((res) => res.json()).then((response) => {
            if (response.result) {
                return response.result;
            } else {
                console.log('Looks like there was a problem: \n', response.error);
                if (response.error === 'Wrong token') {
                    window.location.reload();
                }
                return false;
            }
        }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
            return false;
        });
    });
}

function isEmpty(variable) {
    if (variable === null || variable === undefined) {
        return true;
    }
    if (typeof variable === 'string' && variable.trim() === '') {
        return true;
    }
    if (Array.isArray(variable) && variable.length === 0) {
        return true;
    }

    return typeof variable === 'object' && Object.keys(variable).length === 0;
}

export default backendRequest;
