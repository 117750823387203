<template>
  <div class="login">

    <div class="left_side" :style="onload ? 'left: 70px; opacity: .7;' : null">

      <form @submit.prevent="signInClicked" class="form" v-if="!forgot_password">

        <h2>Log In</h2>
        <p>The Book of Knowledge<br/> for your business</p>

        <p class="err_message" v-if="err_login || err_pass || err_forget" v-text="errMsg"></p>

        <label>
          <input type="text" placeholder="Login" :class="{ 'err_login': err_login }" v-model="login"
                 @keypress="err_login = false; err_pass = false">
        </label>

        <label>
          <div class="caps_caution" v-if="caps">Caps lock is on</div>
          <input :type="passState.fieldType" placeholder="Password" v-model="password" :class="{ 'err_pass': err_pass }"
                 @keypress="err_login = false; err_pass = false">
          <span class="eye" @click="passToggle">
            <span v-if="passState.revealed"></span>
          </span>
        </label>

        <div class="forgot"
             @click="forgot_password = true; login=''; password=''; err_login = false; err_pass = false;">
          Forgot password
        </div>
        <br>

        <button class="submit_login" @click="signInClicked">Log in</button>
      </form>
      <form @submit.prevent="signInClicked" class="form" v-if="forgot_password">
        <h2>Log In</h2>
        <p>Enter your bOpus account<br/> email address:</p>
        <label>
          <input type="text" placeholder="Enter your email" :class="{ 'err_login': err_forget }" v-model="email"
                 @keypress="err_forget = false; err_forget = false">
        </label>
        <!-- <div class="forgot"
             @click="forgot_password = false; err_forget = false; err_login = false; err_pass = false; email = ''">
          Go back to Login
        </div> -->
        <div class="button_group">
          <button class="submit_login back_to_login" @click="forgot_password = false; err_forget = false; err_login = false; err_pass = false; email = ''">Back to sign in</button>
          <button class="submit_login" @click="sendEmailClicked">Send Instructions</button>
        </div>
      </form>
    </div>

    <div class="right_side" :style="onload ? 'right: 70px; opacity: .7;' : null">
      <div class="top"></div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script setup>

import {reactive, ref, onMounted} from "vue";
import {SuperTokensController as STC} from '../services/SuperTokensController.js';
import backendRequest from "@/services/backendRequest";

onMounted(() => {
  localStorage.removeItem('edit');
  onload.value = false;
  // let link = localStorage.getItem('linkAuth');
  // if (link !== null) {
  //   localStorage.removeItem('linkAuth');
  //   linkAuth(link);
  // }
});

let passState = reactive({
  revealed: false,
  fieldType: 'password',
});

const onload = ref(true);

const passToggle = () => {
  passState.revealed = !passState.revealed;
  passState.fieldType = passState.fieldType === 'password' ? 'text' : 'password';
};

let caps = ref(false);
let login = ref('');
let password = ref('');
let err_login = ref(false);
let err_pass = ref(false);
let forgot_password = ref(false);
let errMsg = ref('Wrong Login or Password');
let hrefLink = ref(null);

let link = localStorage.getItem('linkAuth');
if (link !== null) {
  localStorage.removeItem('linkAuth');
  linkAuth(link);
}

document.addEventListener('keyup', (e) => {
  if (e.getModifierState('CapsLock')) {
    caps.value = true;
  } else {
    caps.value = false;
  }
});

async function signInClicked() {
  STC.signIn(login.value, password.value).then((response) => {
    if (response.result) {
      let user = response.data;
      STC.getCurrentUser().then((response) => {
        if (response.roles.length < 1 || response.roles.includes('disabled')) {
          errMsg.value = 'Wrong url domain name for this user or user have been disabled';
          STC.logout();
          return;
        }
        user.email = user.email === undefined ? user.emails[0] : user.email;
        user.metadata = response.metadata;
        user.token = response.token;
        //user.role = user.metadata.role;
        user.role = response.roles;
        user.changePass = user.metadata.changePass !== undefined ? user.metadata.changePass: '';
        localStorage.setItem("bOpus_user", JSON.stringify(user));
        if (user.metadata.exposedTreeNodes !== undefined) {
          localStorage.setItem("exposedTreeNodes", JSON.stringify(user.metadata.exposedTreeNodes));
        }
        window.location.href = (hrefLink.value !== null ? hrefLink.value : "/");
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    } else {
      errMsg.value = response.message;
      switch (response.status) {
        case "FIELD_ERROR":
          switch (response.formFieldName) {
            case "email":
              err_login.value = true;
              break;
            case "password":
              err_pass.value = true;
              break;
          }
          break;
        case "WRONG_CREDENTIALS_ERROR":
          err_login.value = true;
          err_pass.value = true;
          break;
        default:
          console.log(response);
          window.alert(errMsg.value);
          break;
      }
    }
  });
}

let email = ref('');
let err_forget = ref(false);

async function sendEmailClicked() {
  STC.sendPasswordResetEmail(email.value).then((response) => {
    if (response.result) {
      window.alert(response.message);
    } else {
      err_forget.value = true;
      window.alert(response.message);
    }
  });
}

async function linkAuth(link) {
  let info = await backendRequest(
      'authLink/decrypt',
      {
        "data": link
      }
  );
  if (info.login === undefined || info.password === undefined) {
    window.location.href = "/login";
  }
  login.value = info.login;
  password.value = info.password;
  hrefLink.value = info.link;
  await signInClicked();
}
</script>

<style lang="scss" scoped>
.login {
  background-color: #f2f2f2;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .left_side, .right_side {
    transition: all 0.3s ease;
  }

  .left_side {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #f2f2f2;

    .form {
      overflow: hidden;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -180px;
      width: 400px;
      text-align: center;
      background-image: url(../../public/img/bopus/bopus.svg);
      background-size: 200px;
      background-position: center top;
      background-repeat: no-repeat;
      padding-top: 100px;
      transform: translate(-50%, -100px);

      .button_group{
        display: flex;
        justify-content: space-between;
        margin-top: 46px;
        .back_to_login{
          margin-right: 16px;
          background-color: transparent;
          color: #787878;
          border: 1px solid #787878;
          cursor: pointer;
        }
        .back_to_login:hover{
          color: #006C3E;
          border: 1px solid #006C3E;
        }
      }

      .fields {
        width: 800px;
        max-width: calc(100% * 2);
        position: relative;
        text-align: left;
        transition: all .3s ease;
      }

      .forgot {
        font-size: 1rem;
        line-height: 1rem;
        text-align: right;
        cursor: pointer;
        color: #006C3E;
        margin: 16px 0 36px;
        max-width: 400px;
      }

      .err_login, .err_pass {
        border: 1px solid #CD180A;
      }

      .err_message {
        margin: 0;
        font-size: 0.82rem;
        line-height: 1rem;
        text-align: left;
        margin-top: -10px;
        margin-bottom: 10px;
        color: #CD180A;
        text-indent: 10px;
      }

      label {
        position: relative;
        // width: 50%;
        margin-top: 26px;
        display: block;
        left: 0;
        .caps_caution{
          position: absolute;
          color: #1467d0;
          font-size: .75rem;
          line-height: .82rem;
          top: -1rem;
          font-weight: 500;
          right: 0;
        }
        .caps_caution::before{
          display: inline-block;
          content: 'i';
          color: #fff;
          background-color: #1467d0;
          height: .8rem;
          width: .8rem;
          line-height: .9rem;
          font-size: .6rem;
          border-radius: 50%;
          font-style: italic;
          position: absolute;
          left: -.9rem;
          top: -1px;
          text-align: center;
          text-indent: -2px;
        }
      }

      .eye {
        display: inline-block;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        top: 10px;
        background-image: url(../../public/img/password.png);
        background-position: center;
        background-repeat: no-repeat;
				cursor: pointer;
        span {
          display: inline-block;
          height: 27px;
          width: 2px;
          background-color: #787878;
          transform: rotate(60deg);
          position: relative;
          top: 7px;
          right: 0px;
        }
      }

      > p {
        color: #787878;
        font-size: 1rem;
        line-height: 1.2rem;
        margin-bottom: 40px;
      }

      h2 {
        font-size: 1.53rem;
        line-height: 1.8rem;
        height: 40px;
        margin-bottom: 16px;
      }

      input, button {
        max-width: 400px;
        box-sizing: border-box;
        border: none;
        outline: none;
        border-radius: 8px;
        transition: all .3s;
      }

      button:hover {
        background-color: #f2f2f2;
        color: #006C3E;
        border: 1px solid #006C3E;
      }

      input {
        width: 100%;
        border: 1px solid #fff;
        box-sizing: border-box;
        height: 60px;
        line-height: 60px;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.02);
        text-indent: 16px;
        color: #787878;
        font-size: 1rem;
        position: relative;
      }

      input::placeholder {
        color: #787878;
        font-size: 1rem;
        font-family: 'Inter', sans-serif;
      }

      button {
        width: 100%;
        height: 60px;
        background-color: #006C3E;
        font-weight: 500;
        font-size: 1rem;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .right_side {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background-color: #006C3E;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../public/img/w7login.png);

    .top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 40%;
      transform: rotate(180deg);
    }

    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40%;
    }

    .top, .bottom {
      background-image: url(../../public/img/vectors.png);
      background-position: bottom;
      background-repeat: repeat-x;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px) and (min-height: 961px) and (orientation: portrait) {
  .login {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: top;
      background-repeat: repeat-x;
      width: 100%;
      background-size: 540px;

      .form {
        background-image: url(../../public/img/bopus/bopus.svg);
        background-size: 180px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 100px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-10%);
      }
    }

    .right_side {
      display: none;
    }
  }
}

@media screen and (max-width: 599px) and (orientation: portrait) {
  .login {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: center -60px;
      background-repeat: repeat-x;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      .form {
        background-image: url(../../public/img/bopus/bopus.svg);
        background-size: 120px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 70px;
        width: inherit;
        box-sizing: border-box;
        position: relative;
        margin-top: 230px;
        text-align: center;
        top: auto;
        left: auto;
        margin-left: auto;
        transform: translateX(0%);

        > p {
          color: #787878;
          font-size: 1rem;
          line-height: 1.2rem;
          margin-bottom: 16px;
          margin-top: 8px;
        }

        h2 {
          font-size: 1.53rem;
          line-height: 1.8rem;
          height: 32px;
          margin-bottom: 8px;
        }
      }
    }

    .right_side {
      display: none;
    }
  }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
  .login {
    .left_side {
      width: 100%;
    }

    .right_side {
      display: none;
    }
  }
}

.slide-err-enter-active,
.slide-err-leave-active {
  transition: all 0.3s ease-out;
}

.slide-err-enter-from,
.slide-err-leave-to {
  margin-top: -30px;
}
</style>